import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { ButtonContent } from '../boton/ButtonContent'
import { BiPhoneCall } from 'react-icons/bi'
import { BsCalendar4Range } from 'react-icons/bs'
import Navmenu from './NavMenu'
import { Link } from 'react-router-dom'


const hourslogo ="https://firebasestorage.googleapis.com/v0/b/archivos-multimedia.appspot.com/o/24-7_Mesa%20de%20trabajo%201.png?alt=media&token=48fe8986-d091-4005-b184-839499833b07"

const HeaderTwo = ({ btnName }) => {

    const { rpdata } = useContext(GlobalDataContext)

    return (
        <header className=' z-[15] w-full'>
            <div className='flex'>
                <div className='w-full  md:w-[40%] lg:w-[21%] bg-2'>
                    <img
                        src={rpdata?.dbPrincipal?.logo}
                        alt='logo'
                        loading='lazy'
                        className='w-[80%] mx-auto'
                    />
                </div>

                <div className='hidden md:flex w-[65%] lg:w-[72%] mx-auto justify-center items-center bg-2'>
                    <div className='flex space-x-3 w-full mx-auto justify-center'>
                        <div className='hidden w-[40%] text-white border-r-[2px] border-dotted border-white lg:flex mx-auto justify-center'>
                            <div className='w-[15%] self-center mr-3'>
                                <BiPhoneCall fontSize={35} />
                            </div>
                            <div>
                                <a
                                    href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                                >
                                    <p className='-mb-3'>Phone Number</p>
                                    <h5 className='text-[21px]'>
                                        {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                                    </h5>
                                </a>
                                <a
                                    href={`tel:+1${rpdata?.dbPrincipal?.phones?.[1]?.phone}`}
                                >
                                    <p className='-mb-3'>Phone Number</p>
                                    <h5 className='text-[21px]'>
                                        {rpdata?.dbPrincipal?.phones?.[1]?.phone}
                                    </h5>
                                </a>
                            </div>
                        </div>
                        <div className=' hidden w-[30%] text-white border-r-[2px] border-dotted border-white bp lg:flex mx-auto justify-center'>
                            <div className='w-[15%] self-center mr-3'>
                                <BsCalendar4Range fontSize={35} />
                            </div>
                            <div>
                                <p className='-mb-3'>Workdays</p>
                                <h5 className='text-[21px]'>
                                    {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                </h5>
                            </div>
                        </div>
                        <div className='hidden w-[40%] text-white  lg:flex mx-auto justify-center'>
                            {/* <div className='w-[15%] self-center mr-3'>
                                <BsClockHistory fontSize={35} />
                            </div> */}
                            <div>
                                {/* <p className='-mb-3'>Work Hours</p>
                                <h5 className='text-[21px]'>
                                    {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                </h5> */}
                                <img className='w-36 -mb-3' src={hourslogo} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hidden bg1 md:flex w-[35%] lg:w-[18%] mx-auto justify-center items-center border-white border-l-2'>
                    <Link
                        to={`/${btnName === 'view all services' ? 'services' : 'contact'}`}
                    >
                        <span className='capitalize text-white text-[20px] font-semibold'>
                            {btnName ? btnName : 'contact Us'}
                        </span>
                    </Link>
                </div>

            </div>

            <div className='bg-white px-3 py-4'>
                <div className='flex justify-between md:justify-center items-center'>
                    <nav>
                        <Navmenu />
                    </nav>
                    <div className='block md:hidden'>
                        <ButtonContent />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderTwo